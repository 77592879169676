import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  }
  ,
  { path: 'contacto'
      // , canActivate: [AuthGuard] 
      , loadChildren: './pages/contacto/contacto.module#ContactoPageModule' 
  }
  ,
  { path: 'home',
    loadChildren: './pages/home/home.module#HomePageModule'
  }
  ,
  { path: 'linea-blanca'
      , loadChildren: './pages/linea-blanca/linea-blanca.module#LineaBlancaPageModule' 
  }  
  , { path: 'linea-blanca/producto/:id'
      , loadChildren: './pages/linea-blanca-producto/linea-blanca-producto.module#LineaBlancaProductoPageModule' 
  }
  ,  
  {
    path: 'list',
    loadChildren: './list/list.module#ListPageModule'
  }
  , { path: 'about'
    , loadChildren: './pages/about/about.module#AboutPageModule' 
  }
  , { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' }
  , { path: 'inicio', canActivate: [AuthGuard] 
    , loadChildren: './pages/inicio/inicio.module#InicioPageModule'
  }
  , { path: 'afiliado/:pensionNumero/aportaciones', loadChildren: './pages/aportaciones/aportaciones.module#AportacionesPageModule' }
  , { path: 'prestamopagos', loadChildren: './pages/prestamopagos/prestamopagos.module#PrestamopagosPageModule' }
  , { path: 'afiliado/:pensionNumero/prestamo/:prestamoId'
      , loadChildren: './pages/prestamo/prestamo.module#PrestamoPageModule' 
  }
  , { path: 'afiliado/:pensionNumero/prestamo/:prestamoNumero/pagos'
    , loadChildren: './pages/prestamopagos/prestamopagos.module#PrestamopagosPageModule'
  }
  ,
  { path: 'prestamo-simulador'
      // , canActivate: [AuthGuard] 
      , loadChildren: './pages/prestamo-simulador/prestamo-simulador.module#PrestamoSimuladorPageModule' 
  }
  , 
  { path: 'prestamos'
      , canActivate: [AuthGuard] 
      , loadChildren: './pages/prestamos/prestamos.module#PrestamosPageModule' 
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
