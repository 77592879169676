import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
        title: 'Préstamos Prendarios',
        url: '/linea-blanca',
        icon: 'cash'
    }
    ,
    {
        title: 'Simulador',
        url: '/prestamo-simulador',
        icon: 'calculator'
    }  
    ,
    {
        title: 'Oficinas',
        url: '/contacto',
        icon: 'business'
    }   
  ];

  public appPagesAuthenticated = [
    {
        title: 'Inicio',
        url: '/inicio',
        icon: 'home'
    }
      ,
    {
        title: 'Mis Prestamos',
        url: '/prestamos',
        icon: 'list'
    }
  ];

  authenticated: Boolean;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
    , private authenticationService: AuthenticationService
    , private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.authenticationService.authenticationState.subscribe(state => {
        if (state) {
          // this.router.navigate(['prestamo-simulador']);
          this.router.navigate(['inicio']);
          this.authenticated = true;
        } else {
          this.router.navigate(['home']);
          this.authenticated = false;
        }
      });

    });
  }

  logout() {
    this.authenticationService.logout();
  }
}
