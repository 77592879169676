import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';

import { Afiliado } from './../models/afiliado.model';
import { EnvironmentUrlService } from './../shared/services/environment-url.service';
import { ErrorHandlerService } from '../shared/services/error-handler.service';

const TOKEN_KEY = 'auth-token';
const BEARERTOKEN_KEY = 'bearer-token'
 
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
 
  public user: Afiliado;
  public bearerToken: string;

  authentication = new BehaviorSubject<any>(false);
  authenticationState = new BehaviorSubject(false);
 
  constructor(
      private storage: Storage,
      private plt: Platform,
      private environmentUrlService: EnvironmentUrlService,
      private errorHandler: ErrorHandlerService,
      private http: HttpClient, 
    ) { 
    this.plt.ready().then(() => {
      // this.obtenerX();
      this.checkToken(true);
    });
  }

  checkToken(validar:boolean) {
    console.log('AuthenticationService.checkToken.begin');
    this.storage.get('user').then(res => {
      if (res) {
        console.log('checkToken.user', res );
        this.user = res;
        this.storage.get('TOKEN_DATETIME').then(res => {
          if (res) {
            console.log('checkToken.TOKEN_DATETIME', res );
            var tokenDateTime = res as number;
            var today = new Date().getTime();
            var diffMs = (today-tokenDateTime); // milliseconds between now & Christmas
            console.log('checkToken.diffMs', diffMs );
            var diffDays = Math.floor(diffMs / 86400000); // days
            var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
            var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
            console.log('checkToken.diffMins', diffMins );
            if(  diffMins > 5 ){
              this.login( this.user, validar );
            } else {
              this.storage.get(TOKEN_KEY).then(res => {
                if (res) {
                  console.log('checkToken.TOKEN_KEY', res );
                  this.bearerToken = res;
                  if( validar ){
                    this.authentication.next(true);
                    this.authenticationState.next(true);  
                  }
                } else {
                  this.authenticationState.next(false);        
                }
              });
            }
          } else {
            this.authenticationState.next(false);  
          }
        });
      } else {
        this.authenticationState.next(false);  
      }
    });
    console.log('AuthenticationService.checkToken.end');
  }
 
  login(afiliado:Afiliado, validar:Boolean) {
    this.authentication.next(true);
    this.user = afiliado;
    console.log('AuthenticationService.login.afiliado', afiliado);
    let apiUrl = `${this.environmentUrlService.simap42APIUrl}/login?account=${afiliado.pensionNumero}&password=${afiliado.nombre}`;
    this.http.post(apiUrl, {}, { headers: new HttpHeaders({'Content-Type': 'application/json'})} )
      .subscribe(res => {
        // $('#successModal').modal();
        console.log( 'repository.create', res );
        var response = res as any;
        // this.setBearerToken( response.token );    
        this.storage.set('user', afiliado).then(() => {
          this.storage.set('TOKEN_DATETIME', new Date().getTime() ).then(() => {
            this.storage.set(TOKEN_KEY, response.token).then(() => {
              this.bearerToken = response.token;
              if( validar ){
                this.authentication.next(true);
                this.authenticationState.next(true);  
              }
              return true;
            });
          });
        });       
      },
      (error => {
        this.errorHandler.handleError(error);
        // this.errorMessage = this.errorHandler.errorMessage;
        console.log( 'AuthenticationService.login', this.errorHandler.errorMessage );
        if( validar ){
          this.authentication.next(this.errorHandler);
          return false;
        } else {
          this.authenticationState.next(false);  
          return false;
        }
      })
    ) 
  }
 
  logout() {
    this.storage.remove('user').then(() => {
      return this.storage.remove(TOKEN_KEY).then(() => {
        this.authenticationState.next(false);
      });
    });
    
  }
 
  isAuthenticated() {
    return this.authenticationState.value;
  }

  getBearerToken(){
    console.log('AuthenticationService.getBearerToken.begin');
    this.checkToken(false);
    console.log('AuthenticationService.getBearerToken.end');
    return this.bearerToken;
  }

  setBearerToken(bearerToken:string){
    this.bearerToken = bearerToken;
    // this.storage.set(BEARERTOKEN_KEY,bearerToken).then(() => {
    // });
  }


 
}