import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';

import { Prestamo } from './../../models/prestamo.model';

@Component({
  selector: 'app-prestamo-component',
  templateUrl: './prestamo.component.html',
  styleUrls: ['./prestamo.component.scss']
})
export class PrestamoComponent implements OnInit {
    // @Input() public prestamo: Prestamo;
    @Input() public prestamo: any;
    @Input() public pensionNumero: any;

  constructor() { }

  ngOnInit() {
  }

}
