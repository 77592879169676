import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
 
@Injectable()
export class EnvironmentUrlService {
 
  public urlAddress: string = environment.urlAddress;
  public presimapAPIUrl: string = environment.presimapAPIUrl;
  public a1GangasWAPIUrl: string = environment.a1GangasWAPIUrl;
  
  public simapMovilWAPIUrl: string = environment.simapMovilWAPIUrl;
  public simap42APIUrl: string = environment.simap42APIUrl;
 
  constructor() { }
 
}