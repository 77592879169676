import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';

import { AuthenticationService } from './../../services/authentication.service';
import { EnvironmentUrlService } from './environment-url.service';
 
@Injectable()
export class RepositoryService {
 
  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient, 
    private envUrl: EnvironmentUrlService,
    private platform: Platform,
  ) { 
    // this.platform.ready().then(() => {
    //   this.authenticationService.checkToken(false);
    // });
  }
 
  public getData(route: string) {
    return this.http.get(route);
  }

  public getAuthData(route: string) {
    return this.http.get(route, this.generateAuthHeaders());
  }
 
  public create(route: string, body) {
    return this.http.post(route, body, this.generateHeaders());
  }
 
  public update(route: string, body){
    return this.http.put(this.createCompleteRoute(route, this.envUrl.urlAddress), body, this.generateHeaders());
  }
 
  public delete(route: string){
    return this.http.delete(this.createCompleteRoute(route, this.envUrl.urlAddress));
  }
 
  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}/${route}`;
  }
 
  private generateHeaders() {
    return {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }
  }

  private generateAuthHeaders() {
    var token = this.authenticationService.getBearerToken().toString();
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      })
    }
  }
}
