import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-espera',
  templateUrl: './espera.component.html',
  styleUrls: ['./espera.component.scss']
})
export class EsperaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
