import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { PrestamoComponent } from './components/prestamo/prestamo.component';
import { EsperaComponent } from './components/espera/espera.component';

@NgModule({
  imports: [ IonicModule
    , CommonModule
    , RouterModule
  ],
  declarations: [ EsperaComponent
    , PrestamoComponent
  ]
  , exports: [ EsperaComponent
    , PrestamoComponent
  ]
})
export class AppSharedModule {}
