import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppSharedModule } from './app-shared.module';

//import { AportacionComponent } from './aportacion/aportacion.component';
//import { PrestamoComponent } from './components/prestamo/prestamo.component';
//import { EsperaComponent } from './components/espera/espera.component';

import { EnvironmentUrlService } from './shared/services/environment-url.service';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { RepositoryService } from './shared/services/repository.service';

@NgModule({
  declarations: [AppComponent
    // , PrestamoComponent
  ]
  , entryComponents: [ ]
  , imports: [
    BrowserModule,
    IonicModule.forRoot()
    , IonicStorageModule.forRoot()
    , AppRoutingModule
    , HttpClientModule
    , AppSharedModule
  ]
  , providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ,
    EnvironmentUrlService,
    RepositoryService,
    ErrorHandlerService
  ]
  , bootstrap: [AppComponent]
  , exports: [
    // PrestamoComponent
  ]
})
export class AppModule {}
